import { AuthorityGroup } from '../types/authorityGroup.types'
import { TimeOffRequest, TimeOffRequestFirestoreData, TimeOffStatus } from '../types/timeOff.types'
import { ApprovalFirestoreData } from '../types/approval.types'
import { BaseStatus, BaseStatusType } from '../types/base.types'
import {
  purchaseApprovalsOrder,
  statusPriority,
  timeOffApprovalsOrder,
  timeOffApprovalsOrderAlternative,
  timeOffApprovers,
} from '../constants/approvals.constants'
import { PurchaseRequestFirestoreData, PurchaseStatus } from '../types/purchase.types'

export const appendWithPendingTimeOffApprovalsAndSatus = (data: TimeOffRequestFirestoreData) => {
  const dataWithStatus = { ...data, status: TimeOffStatus.PENDING }
  return { ...dataWithStatus, approvals: preparePendingApprovals(timeOffApprovers) }
}

export const appendWithPendingPurchaseApprovalsAndSatus = (data: PurchaseRequestFirestoreData) => {
  const dataWithStatus = { ...data, status: PurchaseStatus.PENDING }
  return { ...dataWithStatus, approvals: preparePendingApprovals(purchaseApprovalsOrder) }
}

export const preparePendingApprovals = (authorityGroups: AuthorityGroup[]): ApprovalFirestoreData => {
  const approvalData: ApprovalFirestoreData = {} as ApprovalFirestoreData
  authorityGroups.forEach((authorityGroup: AuthorityGroup) => {
    approvalData[authorityGroup] = BaseStatus.PENDING
  })
  return approvalData
}

/**
 * Calculates the status of a request based on the approvals.
 * if at least one approval is rejected, return rejected
 * if the last approval is approved and all previous approvals are pending, return approved
 * if all approvals are approved, return approved
 * else return pending
 *
 * @param {ApprovalFirestoreData} approvals - The approvals map for the request.
 * @returns {BaseStatus} The status of the request.
 */
export const calculateRequestStatus = (approvals: ApprovalFirestoreData, approvalsOrder: AuthorityGroup[]) => {
  const filterdAprovals = approvalsOrder.map((authorityGroup) => approvals[authorityGroup])
  const statuses = Object.values(filterdAprovals)
  if (statuses.includes(BaseStatus.REJECTED)) {
    return BaseStatus.REJECTED
  }
  const lastApprover = approvalsOrder.at(-1) as AuthorityGroup
  const lastStatus = approvals[lastApprover]

  // Check if all statuses (except lastStatus) are PENDING
  const isAllPreviousPending = Object.entries(approvals)
    .filter(([approver]) => approver !== lastApprover) // Exclude last approver
    .every(([_, status]) => status === BaseStatus.PENDING)

  //TODO: this is done in order for the GM request to be approved only by the CEO. This approach is not scalable and should be changed in the future.
  if (lastStatus === BaseStatus.APPROVED && isAllPreviousPending) {
    return BaseStatus.APPROVED
  }

  return statuses.every((status) => status === BaseStatus.APPROVED) ? BaseStatus.APPROVED : BaseStatus.PENDING
}

export const calculateCombinedRequestStatus = (
  approvals: ApprovalFirestoreData,
  approvalsOrder: AuthorityGroup[],
  approvalsOrderAlternative?: AuthorityGroup[]
) => {
  if (!approvalsOrderAlternative) {
    return calculateRequestStatus(approvals, approvalsOrder)
  }

  const case1 = calculateRequestStatus(approvals, approvalsOrder)
  const case2 = calculateRequestStatus(approvals, approvalsOrderAlternative)

  return getHigherStatus(case1, case2)
}

export const getApprovalStatus = (approvals: ApprovalFirestoreData, authorityGroup: AuthorityGroup) => {
  return approvals[authorityGroup]
}

/**
 * Checks if all previous approvers have approved the request.
 *
 * @param {ApprovalFirestoreData} approvals - The approvals map for the request.
 * @param {AuthorityGroup} approverAuthorityGroup - The current authority group to check against.
 * @returns {boolean} True if all previous approvers have approved, false otherwise.
 */
export const hasPreviousPendingApprovals = (
  approvals: ApprovalFirestoreData,
  approverAuthorityGroup: AuthorityGroup | undefined,
  approvalsOrder: AuthorityGroup[],
  requestAuthorityGroup?: AuthorityGroup | undefined
): boolean => {
  if (!approverAuthorityGroup) {
    return false
  }
  const approverIndex = approvalsOrder.indexOf(approverAuthorityGroup)

  if (approverIndex === -1) {
    return true
  }

  if (requestAuthorityGroup) {
    const requestAuthorityGroupIndex = approvalsOrder.indexOf(requestAuthorityGroup)
    if (requestAuthorityGroupIndex !== -1 && requestAuthorityGroupIndex < approverIndex) {
      return false
    }
  }

  const previousApprovals = approvalsOrder.slice(0, approverIndex)
  return previousApprovals.some((authorityGroup) => getApprovalStatus(approvals, authorityGroup) === BaseStatus.PENDING)
}

export const hasPreviousPendingTimeOffApprovals = (timeOffRequest: TimeOffRequest, approverAuthorityGroup: AuthorityGroup | undefined): boolean => {
  const case1 = hasPreviousPendingApprovals(
    timeOffRequest.approvals,
    approverAuthorityGroup,
    timeOffApprovalsOrder,
    timeOffRequest.user?.authorityGroup
  )
  const case2 = hasPreviousPendingApprovals(
    timeOffRequest.approvals,
    approverAuthorityGroup,
    timeOffApprovalsOrderAlternative,
    timeOffRequest.user?.authorityGroup
  )
  return case1 && case2
}

export const hasPreviousPendingPurchaseApprovals = (approvals: ApprovalFirestoreData, currentAuthorityGroup: AuthorityGroup | undefined): boolean => {
  return hasPreviousPendingApprovals(approvals, currentAuthorityGroup, purchaseApprovalsOrder)
}

export const getHigherStatus = (status1: BaseStatusType, status2: BaseStatusType): BaseStatusType => {
  return statusPriority[status1] > statusPriority[status2] ? status1 : status2
}

export const getEquivalentApprover = (
  authorityGroup: AuthorityGroup,
  approvalsOrder: AuthorityGroup[],
  approvalsOrderAlternative: AuthorityGroup[]
): { approver1: AuthorityGroup; approver2: AuthorityGroup } => {
  const approverIndex = approvalsOrder.indexOf(authorityGroup)
  const approverIndexAlternative = approvalsOrderAlternative.indexOf(authorityGroup)

  const approverOrderIdex = approverIndex !== -1 ? approverIndex : approverIndexAlternative

  const approver1 = approvalsOrder[approverOrderIdex]
  const approver2 = approvalsOrderAlternative[approverOrderIdex]

  return { approver1, approver2 }
}
