import React from 'react'
import withCapabilities from '../../hoc/withCapabilities'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { useTranslation } from 'react-i18next'
import { userAtom } from '../../stores/userAtom'
import { useAtom } from 'jotai'
import { purchaseApprovalsOrder } from '../../constants/approvals.constants'
import PurchaseApproverPendingList from '../../features/Purchases/PurchaseApproverPendingList'
import PurchaseApproverPastList from '../../features/Purchases/PurchaseApproverPastList'

const PurchaseApproverPage: React.FC = () => {
  const [user] = useAtom(userAtom)
  const { t } = useTranslation()
  return (
    <>
      {/* Only show the pending list if the user is in authorized approvers */}
      {user?.authorityGroup && purchaseApprovalsOrder.includes(user.authorityGroup) && (
        <PageTemplate title={t('purchase.approverPage.pendingListTitle')}>
          <PurchaseApproverPendingList />
        </PageTemplate>
      )}

      <PageTemplate title={t('purchase.approverPage.pastListTitle')}>
        <PurchaseApproverPastList />
      </PageTemplate>
    </>
  )
}

export default withCapabilities(PurchaseApproverPage)
