import { AuthorityGroups } from '../types/authorityGroup.types'
import { BaseStatus } from '../types/base.types'
import { BaseStatusType } from '../types/base.types'
export const timeOffApprovalsOrder = [AuthorityGroups.HR, AuthorityGroups.GM, AuthorityGroups.CEO]
export const timeOffApprovalsOrderAlternative = [AuthorityGroups.PRO_HR, AuthorityGroups.GM, AuthorityGroups.CEO]
export const purchaseApprovalsOrder = [AuthorityGroups.CFO, AuthorityGroups.GM, AuthorityGroups.CEO]
export const statusPriority: Record<BaseStatusType, number> = {
  [BaseStatus.REJECTED]: 3,
  [BaseStatus.APPROVED]: 2,
  [BaseStatus.PENDING]: 1,
}
export const timeOffApprovers = [...timeOffApprovalsOrder, ...timeOffApprovalsOrderAlternative]
