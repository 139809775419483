import { TimeOffRequest, TimeOffRequestFirestoreData, TimeOffRequestFormData, TimeOffStatus } from '../types/timeOff.types'
import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'
import { QueryKeys } from '../constants/queryKeys.constants'
import { useListenToFirestoreQuery, useUpdateDocument, useWriteDocument } from './useFirestore'
import { appendWithUserId } from '../utils/form.utils'
import { userAtom } from '../stores/userAtom'
import { useAtom } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { appendWithPendingTimeOffApprovalsAndSatus, calculateCombinedRequestStatus, getEquivalentApprover } from '../services/approval.service'
import {
  approvalsAndStatusFirebaseUpdater,
  getDocsAndEnhanceWithUserDetails,
  orderByStartDate,
  whereApprovalNotPending,
  whereApprovalPending,
  whereStatusNotPending,
  whereStatusPending,
  whereUserId,
} from '../services/firestore.service'
import { AuthorityGroup } from '../types/authorityGroup.types'
import {
  approvalsAndStatusCacheUpdater,
  importAndUpdateDataCacheUpdater,
  excludeSameLevelApprovals,
  excludeLowerLeverAuthoritiesFromAproverOwnTimeOffRequestsCombined,
  pendingRequestsCacheUpdater,
} from '../services/query.service'
import { timeOffApprovalsOrder, timeOffApprovalsOrderAlternative } from '../constants/approvals.constants'

/**
 * Custom hook to submit a time-off request.
 *
 * This hook utilizes the `useWriteDocument` hook to handle the submission
 * of time-off requests to the Firestore database. It automatically sets the
 * status of the request to `PENDING` upon submission.
 *
 * @returns {Mutation} A mutation object from react-query that can be used to submit a time-off request.
 *
 * @example
 * const submitTimeOffRequest = useSubmitTimeOffRequest();
 *
 * submitTimeOffRequest.mutate(
 *     { data: timeOffRequestData, user },
 *     { onSuccess: () => {}, onError: () => {} }
 * )
 */
export const useSubmitTimeOffRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }

  const transformData = (data: TimeOffRequestFormData): TimeOffRequestFirestoreData => {
    const dataWithUserId = appendWithUserId(data, user)
    return appendWithPendingTimeOffApprovalsAndSatus(dataWithUserId)
  }

  return useWriteDocument<TimeOffRequestFormData, TimeOffRequestFirestoreData>(FIREBASE_COLLECTIONS.TIME_OFF, transformData)
}

/**
 * Custom hook to listen to pending time-off requests in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * time-off requests with a status of `PENDING` in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToApproverPendingTimeoffRequests();
 */
export const useListenToApproverPendingTimeoffRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const approverAuthority: AuthorityGroup = user.authorityGroup

  const constraints = [whereStatusPending(), whereApprovalPending(approverAuthority), orderByStartDate('asc')]
  return useListenToFirestoreQuery<TimeOffRequest>(
    QueryKeys.PENDING_TIME_OFF_REQUESTS,
    FIREBASE_COLLECTIONS.TIME_OFF,
    constraints,
    pendingRequestsCacheUpdater
  )
}

/**
 * Custom hook to listen to past time-off requests in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * time-off requests with a status of `APPROVED` or `REJECTED` in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToApproverPastTimeoffRequests();
 */
export const useListenToApproverPastTimeoffRequests = () => {
  const constraints = [whereStatusNotPending(), orderByStartDate('desc')]

  return useListenToFirestoreQuery<TimeOffRequest>(
    QueryKeys.PAST_TIME_OFF_REQUESTS,
    FIREBASE_COLLECTIONS.TIME_OFF,
    constraints,
    importAndUpdateDataCacheUpdater
  )
}

/**
 * Custom hook to listen to past time-off requests for an employee in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * time-off requests for a specific employee in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToEmployeePastTimeoffRequests();
 */
export const useListenToEmployeePastTimeoffRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }
  const constraints = [whereUserId(user.uid), orderByStartDate('desc')]

  return useListenToFirestoreQuery<TimeOffRequestFirestoreData>(QueryKeys.PAST_TIME_OFF_REQUESTS, FIREBASE_COLLECTIONS.TIME_OFF, constraints)
}

/**
 * Custom hook to fetch pending time-off requests and update the query cache.
 *
 * This hook utilizes the `useFirestoreQuery` hook to fetch time-off requests
 * with a status of `PENDING` from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: pendingRequests, isLoading, error } = useApproverPendingTimeoffRequests();
 */
export const useApproverPendingTimeoffRequests = (): {
  data: TimeOffRequest[] | undefined
  isLoading: boolean
  error: Error | null
} => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }

  const approverAuthority: AuthorityGroup = user.authorityGroup

  // Construct a query to find documents with pending approvals for the approver's authority group
  const constraints = [whereStatusPending(), whereApprovalPending(approverAuthority), orderByStartDate('asc')]

  return useQuery({
    queryKey: QueryKeys.PENDING_TIME_OFF_REQUESTS,
    queryFn: async () => {
      const data = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.TIME_OFF, constraints)

      const excludedSameLevelApproverReqeusts = excludeSameLevelApprovals(
        data,
        approverAuthority,
        timeOffApprovalsOrder,
        timeOffApprovalsOrderAlternative
      )
      return excludeLowerLeverAuthoritiesFromAproverOwnTimeOffRequestsCombined(excludedSameLevelApproverReqeusts, approverAuthority)
    },
  })
}

/**
 * Custom hook to fetch past time-off requests and update the query cache.
 *
 * This hook utilizes the `useFirestoreQuery` hook to fetch time-off requests
 * with a status of `APPROVED` or `REJECTED` from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: pastRequests, isLoading, error } = useApproverPastTimeoffRequests();
 */
export const useApproverPastTimeoffRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }

  //NOTE: We need to create two queries, one for the authority group and one for the status,
  // because firebase does not support OR conditions in where.
  const authorityGroup: AuthorityGroup = user.authorityGroup
  const { approver1, approver2 } = getEquivalentApprover(authorityGroup, timeOffApprovalsOrder, timeOffApprovalsOrderAlternative)

  const authorityGroupConstraints1 = [whereApprovalNotPending(approver1), orderByStartDate('desc')]
  const authorityGroupConstraints2 = [whereApprovalNotPending(approver2), orderByStartDate('desc')]
  const statusConstraints = [whereStatusNotPending(), orderByStartDate('desc')]

  return useQuery({
    queryKey: QueryKeys.PAST_TIME_OFF_REQUESTS,
    queryFn: async () => {
      // Fetch reqeusts which are processed by the current approver
      const approverProccessedRequests1 = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.TIME_OFF, authorityGroupConstraints1)
      const approverProccessedRequests2 = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.TIME_OFF, authorityGroupConstraints2)

      // Fetch APPROVED and REJECTED requests
      const allNotPendingRequests = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.TIME_OFF, statusConstraints)

      // Combine and filter requests
      return Promise.all([approverProccessedRequests1, approverProccessedRequests2, allNotPendingRequests]).then(
        ([authorityGroupData1, authorityGroupData2, statusData]) => {
          const combinedData = [...(authorityGroupData1 || []), ...(authorityGroupData2 || []), ...(statusData || [])]
          const uniqueData = combinedData.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
          // Sort the uniqueData by startDate in descending order
          return uniqueData?.sort((a, b) => b.startDate.toDate().getTime() - a.startDate.toDate().getTime())
        }
      )
    },
  })
}

/**
 * Custom hook to fetch past time-off requests for an employee and update the query cache.
 * These requests are all requests for the employee, regardless of status.
 *
 * This hook utilizes the `useFirestoreQuery` hook to fetch time-off requests
 * for a specific employee from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: pastRequests, isLoading, error } = useEmployeePastTimeoffRequests();
 */
export const useEmployeePastTimeoffRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }
  const constraints = [whereUserId(user.uid), orderByStartDate('desc')]

  return useQuery({
    queryKey: QueryKeys.PAST_TIME_OFF_REQUESTS,
    queryFn: async () => {
      // Fetch user data
      const snapshot = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.TIME_OFF, constraints)
      return snapshot
    },
  })
}

/**
 * Custom hook to approve a time-off request and update the query cache.
 *
 * This hook utilizes the `useUpdateDocument` hook to update the status of a
 * time-off request to `APPROVED` in the Firestore database.
 *
 * @returns {Function} A function that can be called to approve a time-off request.
 *
 * @example
 * const approveTimeoffRequest = useApproveTimeoffRequest();
 *
 * approveTimeoffRequest(requestId);
 */
export const useApproveTimeoffRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const authorityGroup: AuthorityGroup = user.authorityGroup

  const cacheUpdater = approvalsAndStatusCacheUpdater<TimeOffRequest>(
    authorityGroup,
    TimeOffStatus.APPROVED,
    timeOffApprovalsOrder,
    timeOffApprovalsOrderAlternative
  )
  const firebaseUpdater = approvalsAndStatusFirebaseUpdater(
    authorityGroup,
    TimeOffStatus.APPROVED,
    timeOffApprovalsOrder,
    timeOffApprovalsOrderAlternative
  )

  return useUpdateDocument<TimeOffRequest>(
    FIREBASE_COLLECTIONS.TIME_OFF,
    firebaseUpdater,
    QueryKeys.PENDING_TIME_OFF_REQUESTS,
    QueryKeys.PAST_TIME_OFF_REQUESTS,
    cacheUpdater
  )
}

/**
 * Custom hook to reject a time-off request and update the query cache.
 *
 * This hook utilizes the `useUpdateDocument` hook to update the status of a
 * time-off request to `REJECTED` in the Firestore database.
 *
 * @returns {Function} A function that can be called to reject a time-off request.
 *
 * @example
 * const rejectTimeoffRequest = useRejectTimeoffRequest();
 *
 * rejectTimeoffRequest(requestId);
 */
export const useRejectTimeoffRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const authorityGroup: AuthorityGroup = user.authorityGroup

  const addUpdater = (oldData: TimeOffRequest[], data: TimeOffRequest) => {
    const newApprovals = { ...data.approvals, [authorityGroup]: TimeOffStatus.REJECTED }

    const enhancedData = {
      ...data,
      approvals: newApprovals,
      status: calculateCombinedRequestStatus(newApprovals, timeOffApprovalsOrder, timeOffApprovalsOrderAlternative),
    }
    return [...(oldData || []), enhancedData]
  }

  const firebaseDataUpdater = approvalsAndStatusFirebaseUpdater(
    authorityGroup,
    TimeOffStatus.REJECTED,
    timeOffApprovalsOrder,
    timeOffApprovalsOrderAlternative
  )

  return useUpdateDocument<TimeOffRequest>(
    FIREBASE_COLLECTIONS.TIME_OFF,
    firebaseDataUpdater,
    QueryKeys.PENDING_TIME_OFF_REQUESTS,
    QueryKeys.PAST_TIME_OFF_REQUESTS,
    addUpdater
  )
}
