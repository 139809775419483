import { FirestoreData } from '../types/firestore.types'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { BaseStatus, BaseStatusType } from '../types/base.types'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { calculateCombinedRequestStatus, getEquivalentApprover } from './approval.service'
import { ApprovalFirestoreData } from '../types/approval.types'
import { TimeOffRequest } from '../types/timeOff.types'
import { timeOffApprovalsOrderAlternative, timeOffApprovalsOrder } from '../constants/approvals.constants'

/**
 * Custom hook to update the cached query data for a specific query key in the React Query cache.
 *
 * This hook is useful for optimistically updating the UI with new data
 * without having to refetch from the server. It finds the item with the specified
 * ID in the cached data and merges it with the provided new data.
 *
 * @template T - The type of the data being updated, extending FirestoreDataType.
 * @returns {Function} A function that can be called to update the query data.
 * @param {string} id - The unique identifier of the item to update.
 * @param {QueryKeys} queryKey - The key of the query whose data should be updated.
 * @param {Partial<T>} newData - The new data to merge with the existing item data.
 *
 * @example
 * const updateQueryData = useUpdateQueryData<TimeOffRequest>();
 * updateQueryData('12345', QueryKeys.PENDING_TIME_OFF_REQUESTS, { status: 'APPROVED' });
 */

export const useUpdateQueryData = <T extends FirestoreData>() => {
  const queryClient = useQueryClient()

  return (id: string, queryKey: QueryKey, newData: Partial<T>) => {
    queryClient.setQueryData([queryKey], (oldData: T[] | undefined) => {
      if (!oldData) {
        return []
      }
      return oldData.map((request) => (request.id === id ? { ...request, ...newData } : request))
    })
  }
}

export const approvalsAndStatusCacheUpdater =
  <T extends FirestoreData & { approvals: ApprovalFirestoreData; status: BaseStatusType }>(
    authorityGroup: AuthorityGroup,
    status: BaseStatusType,
    approvalsOrder: AuthorityGroup[],
    approvalsOrderAlternative?: AuthorityGroup[]
  ) =>
  (oldData: T[], data: T): T[] => {
    const approvals = { ...data.approvals, [authorityGroup]: status }
    const updatedStatus = calculateCombinedRequestStatus(approvals, approvalsOrder, approvalsOrderAlternative)

    const updatedData = {
      ...data,
      approvals,
      status: updatedStatus,
    }
    return [...(oldData || []), updatedData]
  }

/**
 * Updates the cache with incoming documents, either by adding new documents or updating existing ones.
 *
 * @template T - The type of the document data, extending FirestoreData.
 * @param {T[]} [oldData=[]] - The existing cached data.
 * @param {T[]} incomingDocuments - The new documents to be added or used to update the cache.
 * @returns {T[]} The updated cache data.
 *
 * @example
 * const updatedCache = importAndUpdateDataCacheUpdater<TimeOffRequest>(oldCache, newDocuments);
 */
export const importAndUpdateDataCacheUpdater = <T extends FirestoreData>(oldData: T[] = [], incomingDocuments: T[]): T[] => {
  const updatedData = [...oldData]

  incomingDocuments.forEach((incomingDoc) => {
    const existingDocIndex = updatedData.findIndex((doc) => doc.id === incomingDoc.id)

    if (existingDocIndex !== -1) {
      // Update the existing document
      updatedData[existingDocIndex] = incomingDoc
    } else {
      // Add the new document
      updatedData.push(incomingDoc)
    }
  })

  return updatedData
}

/**
 * Updates the cache with incoming documents, either by adding new documents or updating existing ones.
 *
 * @template T - The type of the document data, extending FirestoreData.
 * @param {T[]} [oldData=[]] - The existing cached data.
 * @param {T[]} incomingDocuments - The new documents to be added or used to update the cache.
 * @returns {T[]} The updated cache data.
 *
 * @example
 * const updatedCache = pendingRequestsUpdater(_, newDocuments);
 */
export const pendingRequestsCacheUpdater = (
  _: TimeOffRequest[],
  incomingDocuments: TimeOffRequest[],
  approverAuthority: AuthorityGroup
): TimeOffRequest[] => {
  const excludedSameLevelApproverReqeusts = excludeSameLevelApprovals(
    incomingDocuments,
    approverAuthority,
    timeOffApprovalsOrder,
    timeOffApprovalsOrderAlternative
  )
  return excludeLowerLeverAuthoritiesFromAproverOwnTimeOffRequestsCombined(excludedSameLevelApproverReqeusts, approverAuthority)
}

/**
 * Exclude requests that the approver has requested.
 * Apporver cannot approve or reject his own request.
 * Also exclude reqeusts when the current approver has lower authority than the submitter.
 * @param requests - The requests to filter
 * @param approverAuthorityGroup - The authority group of the approver
 * @param approvers - The order of approvals
 */
export const excludeLowerLeverAuthoritiesFromAproverOwnRequests = (
  requests: TimeOffRequest[],
  approverAuthorityGroup: AuthorityGroup,
  approvers: AuthorityGroup[]
): TimeOffRequest[] => {
  return requests.filter((request) => {
    const submitterIndex = approvers.indexOf(request.user?.authorityGroup as AuthorityGroup)
    const approverIndex = approvers.indexOf(approverAuthorityGroup)
    return submitterIndex < approverIndex
  })
}

/**
 * Exclude requests that the approver has requested.
 * Apporver cannot approve or reject his own request.
 * Also exclude reqeusts when the current approver has lower authority than the submitter.
 * @param requests - The requests to filter
 * @param approverAuthorityGroup - The authority group of the approver
 * @param approvers - The order of approvals
 */
export const excludeLowerLeverAuthoritiesFromAproverOwnTimeOffRequestsCombined = (requesets: TimeOffRequest[], approverAuthority: AuthorityGroup) => {
  const exludedLowerLeverAuthorities1 = excludeLowerLeverAuthoritiesFromAproverOwnRequests(requesets, approverAuthority, timeOffApprovalsOrder)
  const exludedLowerLeverAuthorities2 = excludeLowerLeverAuthoritiesFromAproverOwnRequests(
    requesets,
    approverAuthority,
    timeOffApprovalsOrderAlternative
  )

  const combinedRequests = [...exludedLowerLeverAuthorities1, ...exludedLowerLeverAuthorities2]
  const uniqueRequests = combinedRequests.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
  return uniqueRequests
}
/**
 * We asume that the approvalsOrder and approvalsOrderAlternative have the same number of approvers and
 * on each position there is a pair of approvers or the same approver.
 * eg:
 * approvalsOrder: [HR, GM, CEO]
 * approvalsOrderAlternative: [PROHR, GM, CEO]
 * so the HR and PROHR approval are equivalent and only the higher status [REJECTED, APPROVED, PENDING] will be considered.
 *
 * @param requests - The requests to filter
 * @param approverAuthorityGroup - The authority group of the approver
 * @param approvalsOrder - The order of approvals
 * @param approvalsOrderAlternative - The alternative order of approvals
 */
export const excludeSameLevelApprovals = (
  requests: TimeOffRequest[],
  approverAuthorityGroup: AuthorityGroup,
  approvalsOrder: AuthorityGroup[],
  approvalsOrderAlternative: AuthorityGroup[]
) => {
  const { approver1, approver2 } = getEquivalentApprover(approverAuthorityGroup, approvalsOrder, approvalsOrderAlternative)

  return requests.filter((request) => {
    const approvals = request.approvals || {}

    const isApprovedOrRejected = (authority: AuthorityGroup) => {
      const status = approvals[authority]
      return status === BaseStatus.APPROVED || status === BaseStatus.REJECTED
    }

    const hasApprovedOrRejectedInOrder = isApprovedOrRejected(approver1)
    const hasApprovedOrRejectedInAlternativeOrder = isApprovedOrRejected(approver2)
    return !(hasApprovedOrRejectedInOrder || hasApprovedOrRejectedInAlternativeOrder)
  })
}
