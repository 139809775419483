import { AuthorityGroup, AuthorityGroups } from '../types/authorityGroup.types'

export const allAuthorities: AuthorityGroup[] = Object.values(AuthorityGroups)

export const isAuthorized = (authorityGroup: AuthorityGroup) => {
  return allAuthorities.includes(authorityGroup)
}

export const isEmployee = (authorityGroup: AuthorityGroup) => {
  return authorityGroup === AuthorityGroups.EMPLOYEE
}

export const isApprover = (authorityGroup: AuthorityGroup) => {
  return [AuthorityGroups.ADMIN, AuthorityGroups.CEO, AuthorityGroups.GM, AuthorityGroups.PRO_HR, AuthorityGroups.HR, AuthorityGroups.CFO].includes(
    authorityGroup
  )
}
