import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import NotFoundPage from '../pages/NotFoundPage'
import { LinearProgress } from '@mui/material'
import {
  adminMenuItems,
  ceoMenuItems,
  cfoMenuItems,
  employeeMenuItems,
  gmMenuItems,
  hrMenuItems,
  managerMenuItems,
  proHrMenuItems,
  protectedAdminRoutes,
  protectedCEORoutes,
  protectedCFORoutes,
  protectedEmployeeRoutes,
  protectedGMRoutes,
  protectedHRRoutes,
  protectedManagerRoutes,
  protectedProHRRoutes,
  PublicRoutes,
  publicRoutes,
} from '../config/routes.config'
import { useAuth } from '../hooks/useAuth'
import PublicRoute from './PublicRoute'
import { useAtom } from 'jotai'
import { userAtom } from '../stores/userAtom'
import UnauthorizedPage from '../pages/UnauthorizedPage'
import { AuthorityGroups } from '../types/authorityGroup.types'
import { isAuthorized } from '../utils/auth.utils'
import { generateProtectedRoutes } from '../utils/routeUtils'

const AppRoutes: React.FC = () => {
  const { loading } = useAuth()
  const [user] = useAtom(userAtom)

  if (loading) {
    return <LinearProgress sx={{ height: '5px' }} />
  }

  return (
    <Router>
      <Routes>
        {/*----------- Public Routes -----------*/}
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<PublicRoute>{route.element}</PublicRoute>} />
        ))}

        {/*----------- Employee Routes -----------*/}
        {generateProtectedRoutes(protectedEmployeeRoutes, [AuthorityGroups.EMPLOYEE], employeeMenuItems)}

        {/*----------- Manager Routes -----------*/}
        {generateProtectedRoutes(protectedManagerRoutes, [AuthorityGroups.MANAGER], managerMenuItems)}

        {/*----------- HR Routes -----------*/}
        {generateProtectedRoutes(protectedHRRoutes, [AuthorityGroups.HR], hrMenuItems)}

        {/*----------- PROCURMENT HR Routes -----------*/}
        {generateProtectedRoutes(protectedProHRRoutes, [AuthorityGroups.PRO_HR], proHrMenuItems)}

        {/*----------- GM Routes -----------*/}
        {generateProtectedRoutes(protectedGMRoutes, [AuthorityGroups.GM], gmMenuItems)}

        {/*----------- CFO Routes -----------*/}
        {generateProtectedRoutes(protectedCFORoutes, [AuthorityGroups.CFO], cfoMenuItems)}

        {/*----------- CEO Routes -----------*/}
        {generateProtectedRoutes(protectedCEORoutes, [AuthorityGroups.CEO], ceoMenuItems)}

        {/*----------- Admin Routes -----------*/}
        {generateProtectedRoutes(protectedAdminRoutes, [AuthorityGroups.ADMIN], adminMenuItems)}

        {/*----------- Home Redirect -----------*/}
        <Route
          path="/"
          element={(() => {
            if (!user) {
              return <Navigate to={PublicRoutes.LOGIN} />
            }
            if (!user.authorityGroup) {
              return <Navigate to="/403" />
            }
            if (isAuthorized(user.authorityGroup)) {
              return <Navigate to={`/${user.authorityGroup.toLowerCase()}`} />
            }

            return <Navigate to="/403" />
          })()}
        />
        <Route path="*" element={<Navigate to="/404" />} />
        {/*----------- 404 Not Found -----------*/}
        <Route path="/404" element={<NotFoundPage />} />
        {/*----------- 403 Unauthorized -----------*/}
        <Route path="/403" element={<UnauthorizedPage />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
