import React from 'react'
import { HowToReg } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import theme from '../../theme'
import { BaseStatusType } from '../../types/base.types'
import { BaseStatus } from '../../types/base.types'
import { useTranslation } from 'react-i18next'
import { getHigherStatus } from '../../services/approval.service'
interface ApproversStatusProps {
  approversStatus: {
    cfo?: BaseStatusType | null
    ceo?: BaseStatusType | null
    hr?: BaseStatusType | null
    prohr?: BaseStatusType | null
    gm?: BaseStatusType | null
  }
}

const ApproversStatus: React.FC<ApproversStatusProps> = ({ approversStatus }) => {
  const { t } = useTranslation()

  const getColor = (status: BaseStatusType | undefined) => {
    switch (status) {
      case BaseStatus.APPROVED:
        return theme.palette.success.main
      case BaseStatus.REJECTED:
        return theme.palette.error.main
      case BaseStatus.PENDING:
        return theme.palette.grey[500]
      default:
        return 'transparent'
    }
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {approversStatus.hr && approversStatus.prohr && (
        <Tooltip title={t('approverStatus.tooltip.hr')}>
          {(() => {
            const hrStatus = approversStatus.hr
            const prohrStatus = approversStatus.prohr

            return <HowToReg style={{ color: getColor(getHigherStatus(hrStatus, prohrStatus)) }} />
          })()}
        </Tooltip>
      )}
      {approversStatus.cfo && (
        <Tooltip title={t('approverStatus.tooltip.cfo')}>
          <HowToReg style={{ color: getColor(approversStatus.cfo) }} />
        </Tooltip>
      )}
      {approversStatus.gm && (
        <Tooltip title={t('approverStatus.tooltip.gm')}>
          <HowToReg style={{ color: getColor(approversStatus.gm) }} />
        </Tooltip>
      )}

      {approversStatus.ceo && (
        <Tooltip title={t('approverStatus.tooltip.ceo')}>
          <HowToReg style={{ color: getColor(approversStatus.ceo) }} />
        </Tooltip>
      )}
    </Box>
  )
}

export default ApproversStatus
