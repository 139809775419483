import { FIREBASE_COLLECTIONS } from '../constants/firebase.constants'
import { QueryKeys } from '../constants/queryKeys.constants'
import { useListenToFirestoreQuery, useUpdateDocument, useWriteDocument } from './useFirestore'
import { appendWithUserId } from '../utils/form.utils'
import { userAtom } from '../stores/userAtom'
import { useAtom } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { appendWithPendingPurchaseApprovalsAndSatus, calculateRequestStatus } from '../services/approval.service'
import {
  approvalsAndStatusFirebaseUpdater,
  getDocsAndEnhanceWithUserDetails,
  orderCreatedAt,
  whereApprovalNotPending,
  whereApprovalPending,
  whereStatusNotPending,
  whereStatusPending,
  whereUserId,
} from '../services/firestore.service'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { approvalsAndStatusCacheUpdater, importAndUpdateDataCacheUpdater } from '../services/query.service'
import { PurchaseRequest, PurchaseRequestFirestoreData, PurchaseStatus } from '../types/purchase.types'
import { PurchaseRequestFormData } from '../types/purchase.types'
import { appendWithAttachmentsMeta, uploadAttachments } from '../services/storage.service'
import { purchaseApprovalsOrder } from '../constants/approvals.constants'

/**
 * Custom hook to submit a purchase request.
 *
 * This hook utilizes the `useWriteDocument` hook to handle the submission
 * of purchase requests to the Firestore database. It automatically sets the
 * status of the request to `PENDING` upon submission.
 *
 * @returns {Mutation} A mutation object from react-query that can be used to submit a purchase request.
 *
 * @example
 * const submitPurchaseRequest = useSubmitPurchaseRequest();
 *
 * submitPurchaseRequest.mutate(
 *     { data: purchaseRequestData, user },
 *     { onSuccess: () => {}, onError: () => {} }
 * )
 */
export const useSubmitPurchaseRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }
  const transformData = async (data: PurchaseRequestFormData): Promise<PurchaseRequestFirestoreData> => {
    const attachments = await uploadAttachments(data.attachments)
    const dataWithAttachments = appendWithAttachmentsMeta(data, attachments)
    const dataWithUserId = appendWithUserId(dataWithAttachments, user)
    return appendWithPendingPurchaseApprovalsAndSatus(dataWithUserId) as PurchaseRequestFirestoreData
  }

  return useWriteDocument<PurchaseRequestFormData, PurchaseRequestFirestoreData>(FIREBASE_COLLECTIONS.PURCHASES, transformData)
}

/**
 * Custom hook to listen to pending purchase requests in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * purchase requests with a status of `PENDING` in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToApproverPendingPurchaseRequests();
 */
export const useListenToApproverPendingPurchaseRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const userAuthority: AuthorityGroup = user.authorityGroup

  const constraints = [whereStatusPending(), whereApprovalPending(userAuthority), orderCreatedAt('asc')]
  return useListenToFirestoreQuery<PurchaseRequestFirestoreData>(QueryKeys.PENDING_PURCHASE_REQUESTS, FIREBASE_COLLECTIONS.PURCHASES, constraints)
}

/**
 * Custom hook to listen to past purchase requests in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * purchase requests with a status of `APPROVED` or `REJECTED` in the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToApproverPastPurchaseRequests();
 */
export const useListenToApproverPastPurchaseRequests = () => {
  const constraints = [whereStatusNotPending(), orderCreatedAt('desc')]

  return useListenToFirestoreQuery<PurchaseRequestFirestoreData>(
    QueryKeys.PAST_PURCHASE_REQUESTS,
    FIREBASE_COLLECTIONS.PURCHASES,
    constraints,
    importAndUpdateDataCacheUpdater
  )
}

/**
 * Custom hook to listen to past purchase requests for an employee in real-time and update the query cache.
 *
 * This hook utilizes the `useListenToFirestoreQuery` hook to listen to
 * purchase requests for a specific employee from the Firestore database.
 *
 * @returns {Object} An object containing the real-time data, loading state, and error state.
 *
 * @example
 * const { data, isLoading, error } = useListenToEmployeePastPurchaseRequests();
 */
export const useListenToEmployeePastPurchaseRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }
  const constraints = [whereUserId(user.uid), orderCreatedAt('desc')]

  return useListenToFirestoreQuery<PurchaseRequestFirestoreData>(QueryKeys.PAST_PURCHASE_REQUESTS, FIREBASE_COLLECTIONS.PURCHASES, constraints)
}

/**
 * Custom hook to fetch pending purchase requests and update the query cache.
 *
 * This hook utilizes the `useQuery` hook to fetch purchase requests
 * with a status of `PENDING` from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: pendingRequests, isLoading, error } = useApproverPendingPurchaseRequests();
 */
export const useApproverPendingPurchaseRequests = (): {
  data: PurchaseRequest[] | undefined
  isLoading: boolean
  error: Error | null
} => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }

  const authorityGroup: AuthorityGroup = user.authorityGroup

  // Construct a query to find documents with pending approvals for the user's authority group
  const constraints = [whereStatusPending(), whereApprovalPending(authorityGroup), orderCreatedAt('asc')]

  return useQuery({
    queryKey: QueryKeys.PENDING_PURCHASE_REQUESTS,
    queryFn: async () => await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.PURCHASES, constraints),
  })
}

/**
 * Custom hook to fetch past purchase requests and update the query cache.
 *
 * This hook utilizes the `useQuery` hook to fetch purchase requests
 * with a status of `APPROVED` or `REJECTED` from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: pastRequests, isLoading, error } = useApproverPastPurchaseRequests();
 */
export const useApproverPastPurchaseRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }

  //NOTE: We need to create two queries, one for the authority group and one for the status,
  // because firebase does not support OR conditions in where.

  const authorityGroup: AuthorityGroup = user.authorityGroup
  const authorityGroupConstraints = [whereApprovalNotPending(authorityGroup), orderCreatedAt('desc')]
  const statusConstraints = [whereStatusNotPending(), orderCreatedAt('desc')]

  return useQuery({
    queryKey: QueryKeys.PAST_PURCHASE_REQUESTS,
    queryFn: async () => {
      // Fetch authority group data
      const authorityGroupData = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.PURCHASES, authorityGroupConstraints)

      // Fetch status data
      const statusData = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.PURCHASES, statusConstraints)

      // Combine and filter data
      return Promise.all([authorityGroupData, statusData]).then(([authorityGroupData, statusData]) => {
        const combinedData = [...(authorityGroupData || []), ...(statusData || [])]
        const uniqueData = combinedData.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
        // Sort the uniqueData by createdAt in descending order
        return uniqueData?.sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())
      })
    },
  })
}

/**
 * Custom hook to fetch past purchase requests for an employee and update the query cache.
 *
 * This hook utilizes the `useQuery` hook to fetch purchase requests
 * for a specific employee from the Firestore database.
 *
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 *
 * @example
 * const { data: §, isLoading, error } = useEmployeePastPurchaseRequests();
 */
export const useEmployeePastPurchaseRequests = () => {
  const [user] = useAtom(userAtom)

  if (!user) {
    throw new Error('User not found')
  }
  const constraints = [whereUserId(user.uid), orderCreatedAt('desc')]

  return useQuery({
    queryKey: QueryKeys.PAST_PURCHASE_REQUESTS,
    queryFn: async () => {
      // Fetch user data
      const snapshot = await getDocsAndEnhanceWithUserDetails(FIREBASE_COLLECTIONS.PURCHASES, constraints)
      return snapshot
    },
  })
}

/**
 * Custom hook to approve a purchase request and update the query cache.
 *
 * This hook utilizes the `useUpdateDocument` hook to update the status of a
 * purchase request to `APPROVED` in the Firestore database.
 *
 * @returns {Function} A function that can be called to approve a purchase request.
 *
 * @example
 * const approvePurchaseRequest = useApprovePurchaseRequest();
 *
 * approvePurchaseRequest(requestId);
 */
export const useApprovePurchaseRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const authorityGroup: AuthorityGroup = user.authorityGroup

  const cacheUpdater = approvalsAndStatusCacheUpdater<PurchaseRequest>(authorityGroup, PurchaseStatus.APPROVED, purchaseApprovalsOrder)
  const firebaseUpdater = approvalsAndStatusFirebaseUpdater(authorityGroup, PurchaseStatus.APPROVED, purchaseApprovalsOrder)

  return useUpdateDocument<PurchaseRequest>(
    FIREBASE_COLLECTIONS.PURCHASES,
    firebaseUpdater,
    QueryKeys.PENDING_PURCHASE_REQUESTS,
    QueryKeys.PAST_PURCHASE_REQUESTS,
    cacheUpdater
  )
}

/**
 * Custom hook to reject a purchase request and update the query cache.
 *
 * This hook utilizes the `useUpdateDocument` hook to update the status of a
 * purchase request to `REJECTED` in the Firestore database.
 *
 * @returns {Function} A function that can be called to reject a purchase request.
 *
 * @example
 * const rejectPurchaseRequest = useRejectPurchaseRequest();
 *
 * rejectPurchaseRequest(requestId);
 */
export const useRejectPurchaseRequest = () => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const authorityGroup: AuthorityGroup = user.authorityGroup

  const addUpdater = (oldData: PurchaseRequest[], data: PurchaseRequest) => {
    const newApprovals = { ...data.approvals, [authorityGroup]: PurchaseStatus.REJECTED }

    const enhancedData = {
      ...data,
      approvals: newApprovals,
      status: calculateRequestStatus(newApprovals, purchaseApprovalsOrder),
    }
    return [...(oldData || []), enhancedData]
  }

  const firebaseDataUpdater = approvalsAndStatusFirebaseUpdater(authorityGroup, PurchaseStatus.REJECTED, purchaseApprovalsOrder)

  return useUpdateDocument<PurchaseRequest>(
    FIREBASE_COLLECTIONS.PURCHASES,
    firebaseDataUpdater,
    QueryKeys.PENDING_PURCHASE_REQUESTS,
    QueryKeys.PAST_PURCHASE_REQUESTS,
    addUpdater
  )
}
