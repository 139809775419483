import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  #home-page-wrapper {
    min-height: unset;
    height: calc(-60px + 100vh);

    p {
      margin-top: 0px;
      margin-bottom: 0.9rem;
    }
  }
`
