import { Box, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorPage from '../common/ErrorPage'
import { useTranslation } from 'react-i18next'
import { Wrapper } from './WordPressPasswordProtectedPage.styled'

const WordPressPasswordProtectedPage = () => {
  const { t } = useTranslation()

  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const wordpressPassword = process.env.REACT_APP_WORDPRESS_PASSWORD

  // The WordPress page ID you want to fetch
  const pageId = '590' // grains2drops.eu/crew

  const injectStylesheets = () => {
    const stylesheets = [
      'https://grains2drops.eu/wp-content/plugins/mailchimp-for-wp/assets/css/form-themes.css',
      //'https://grains2drops.eu/wp-content/themes/hello-elementor/style.min.css',
      //'https://grains2drops.eu/wp-content/themes/hello-elementor/theme.min.css',
      'https://grains2drops.eu/wp-content/plugins/elementor/assets/css/frontend-lite.min.css',
      'https://grains2drops.eu/wp-content/uploads/elementor/css/post-4.css',
      'https://grains2drops.eu/wp-content/plugins/elementor/assets/css/modules/lazyload/frontend.min.css',
      'https://grains2drops.eu/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css',
      'https://grains2drops.eu/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css',
      'https://grains2drops.eu/wp-content/uploads/elementor/css/global.css',
      'https://grains2drops.eu/wp-content/uploads/elementor/css/post-590.css',
      //'https://fonts.googleapis.com/css?family=Cardo%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&display=swap',
    ]
    stylesheets.forEach((baseHref) => {
      const hrefWithCacheBuster = `${baseHref}?cb=${new Date().getTime()}` // Add timestamp
      if (!document.querySelector(`link[href^="${baseHref}"]`)) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = hrefWithCacheBuster
        document.head.appendChild(link)
      }
    })
  }

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true)
      setError(false)

      try {
        // Fetch protected content using password in query params
        const url = `https://grains2drops.eu/wp-json/wp/v2/pages/${pageId}?password=${wordpressPassword}`
        const response = await fetch(url)
        const data = await response.json()

        if (data.protected) {
          setError(true)
        } else {
          setContent(data.content.rendered) // Extract and set the content
        }
      } catch (err) {
        setError(true)
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    injectStylesheets() // Inject stylesheets
    fetchContent()
  }, [])

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return (
      <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ErrorPage title={t('homepage.error.title')} description={t('homepage.error.description')} message={t('homepage.error.message')} />
      </Box>
    )
  }

  // elementor-kit-4 is the class name of the elementor page that need to be here in order for the page to be displayed correctly
  return <Wrapper className="elementor-kit-4" dangerouslySetInnerHTML={{ __html: content }} />
}

export default WordPressPasswordProtectedPage
