import {
  addDoc,
  collection,
  updateDoc,
  doc,
  QueryConstraint,
  query,
  DocumentData,
  QuerySnapshot,
  writeBatch,
  getDocs,
  where,
  orderBy,
} from 'firebase/firestore'
import { db } from './firebase.service'
import { appendWithCreatedAt } from '../utils/form.utils'
import { getUsersByIds } from './user.service'
import { calculateCombinedRequestStatus } from './approval.service'
import { ApprovalFirestoreData } from '../types/approval.types'
import { TimeOffRequestFirestoreData } from '../types/timeOff.types'
import { BaseStatus, BaseStatusType } from '../types/base.types'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { PurchaseRequestFirestoreData } from '../types/purchase.types'
import { UsefulFileStatus } from '../types/usefulFiles.types'
import { UserDetails } from '../types/userDetails.types'

/**
 * Submits a new document to a specified Firestore collection.
 *
 * This function appends user information and a timestamp to the data before
 * submitting it to the Firestore database.
 *
 * @template T - The type of the data object to be submitted.
 * @param {T} data - The data object to be submitted.
 * @param {string} collectionName - The name of the Firestore collection.
 * @returns {Promise<string>} A promise that resolves with the document ID.
 * @throws {Error} Throws an error if the submission fails.
 */
export const writeDocument = async <T extends object>(data: T, collectionName: string): Promise<string> => {
  const dataToSubmit = appendWithCreatedAt(data)
  try {
    const collectionRef = collection(db, collectionName)
    const docRef = await addDoc(collectionRef, dataToSubmit)
    return docRef.id
  } catch (error) {
    console.error(`Error adding document to ${collectionName}:`, error)
    throw new Error('Failed to submit document')
  }
}

/**
 * Updates an existing document in a specified Firestore collection.
 *
 * @template T - The type of the data object to be updated.
 * @param {string} id - The ID of the document to be updated.
 * @param {T} data - The data object containing updated fields.
 * @param {string} collection - The name of the Firestore collection.
 * @returns {Promise<void>} A promise that resolves when the document is successfully updated.
 * @throws {Error} Throws an error if the update fails.
 * @example
 * await updateDocument('123', { status: 'approved' }, FIREBASE_COLLECTIONS.TIME_OFF)
 */
export const updateDocument = async <T extends object>(id: string, data: T, collection: string): Promise<void> => {
  try {
    const documentRef = doc(db, collection, id)
    await updateDoc(documentRef, data)
  } catch (error) {
    console.error(`Error updating document with ID: ${id} in collection: ${collection}`, error)
    throw new Error('Failed to update document')
  }
}

/**
 * Fetches documents from a specified Firestore collection and enhances them with user details.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {QueryConstraint[]} [constraints] - Optional query constraints.
 * @returns {Promise<Array<TimeOffRequestFirestoreData & { user: UserDetails | null }>>} A promise that resolves to an array of documents with user details.
 */
export const getDocsAndEnhanceWithUserDetails = async (collectionName: string, constraints?: QueryConstraint[]) => {
  const query = createFirestoreQuery(collectionName, constraints)
  const snapshot = await getDocs(query)
  const documents = mapSnapshotToDocuments<TimeOffRequestFirestoreData>(snapshot)
  return enhanceDocumentsWithUserDetails(documents)
}

/**
 * Creates a Firestore query with optional filters and sorting by createdAt in desc order.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {QueryConstraint[]} [constraints] - Optional filters to apply to the query.
 * @returns {Query<DocumentData>} A Firestore query object.
 */
export const createFirestoreQuery = (collectionName: string, constraints?: QueryConstraint[]) => {
  const collectionRef = collection(db, collectionName)
  return query(collectionRef, ...(constraints || []))
}

/**
 * Maps a Firestore snapshot to an array of documents.
 *
 * @template T - The type of the document data.
 * @param {QuerySnapshot<DocumentData>} snapshot - The Firestore snapshot.
 * @returns {Array<T>} An array of documents with their data.
 */
export const mapSnapshotToDocuments = <T>(snapshot: QuerySnapshot<DocumentData>) => {
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...(doc.data() as T),
  }))
}

/**
 * Combines documents with user details by fetching user information from Firestore.
 *
 * @template T - The type of the document data, which must include a userId field.
 * @param {Array<T>} documents - An array of documents to be combined with user details.
 * @returns {Promise<Array<T & { user: UserDetails | null }>>} A promise that resolves to an array of documents with user details.
 */
export const enhanceDocumentsWithUserDetails = async <T extends { userId: string }>(
  documents: T[]
): Promise<Array<T & { user: UserDetails | null }>> => {
  // Filter out undefined userIds
  const userIds = Array.from(new Set(documents.map((doc) => doc.userId).filter((userId): userId is string => userId !== undefined)))
  if (userIds.length === 0) return documents as Array<T & { user: UserDetails | null }>

  const userMap = await getUsersByIds(userIds)

  return documents.map((doc) => ({
    ...doc,
    user: doc.userId !== undefined ? userMap[doc.userId] : null, // If userId is undefined, set user to null
  }))
}

/**
 * Writes multiple documents to a specified Firestore collection in a batch.
 *
 * @template T - The type of the data objects to be written.
 * @param {Array<T>} documents - An array of data objects to be written.
 * @param {string} collectionName - The name of the Firestore collection.
 * @returns {Promise<void>} A promise that resolves when the batch write is complete.
 * @throws {Error} Throws an error if the batch write fails.
 */
export const writeMultipleDocuments = async <T extends object>(documents: T[], collectionName: string) => {
  const batch = writeBatch(db)
  documents.forEach((document) => {
    const docRef = doc(collection(db, collectionName))
    batch.set(docRef, document)
  })
  try {
    await batch.commit()
    console.log(`Batch write to collection: ${collectionName} completed successfully.`)
  } catch (error) {
    console.error(`Error writing batch to collection: ${collectionName}`, error)
    throw new Error('Failed to write multiple documents')
  }
}

/**
 * Updates the approval and status fields of a time-off request in Firestore.
 *
 * @param {AuthorityGroup} authorityGroup - The authority group of the approver.
 * @param {BaseStatusType} status - The new status of the approval.
 * @returns {Function} A function that takes a time-off request and returns an object with updated fields.
 */
export const approvalsAndStatusFirebaseUpdater =
  (authorityGroup: AuthorityGroup, status: BaseStatusType, approvalsOrder: AuthorityGroup[], approvalsOrderAlternative?: AuthorityGroup[]) =>
  (data: TimeOffRequestFirestoreData | PurchaseRequestFirestoreData) => {
    const approvals: ApprovalFirestoreData = { ...data.approvals, [authorityGroup]: status }
    return {
      [`approvals.${authorityGroup}`]: status,
      status: calculateCombinedRequestStatus(approvals, approvalsOrder, approvalsOrderAlternative),
    }
  }

/**
 * Creates an order by constraint for the startDate field.
 *
 * @param {'asc' | 'desc'} order - The order direction (ascending or descending).
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const orderByStartDate = (order: 'asc' | 'desc') => orderBy('startDate', order)

/**
 * Creates a where constraint for pending approvals for a specific authority group.
 *
 * @param {AuthorityGroup} userAuthority - The authority group of the user.
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereApprovalPending = (userAuthority: AuthorityGroup) => where(`approvals.${userAuthority}`, '==', BaseStatus.PENDING)

/**
 * Creates a where constraint for non-pending approvals for a specific authority group.
 *
 * @param {AuthorityGroup} userAuthority - The authority group of the user.
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereApprovalNotPending = (userAuthority: AuthorityGroup) =>
  where(`approvals.${userAuthority}`, 'in', [BaseStatus.APPROVED, BaseStatus.REJECTED])

/**
 * Creates a where constraint for pending status.
 *
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereStatusPending = () => where('status', '==', BaseStatus.PENDING)

/**
 * Creates a where constraint for non-pending status.
 *
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereStatusNotPending = () => where('status', 'in', [BaseStatus.APPROVED, BaseStatus.REJECTED])

/**
 * Creates a where constraint for a specific user ID.
 *
 * @param {string} userId - The user ID.
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereUserId = (userId: string) => where('userId', '==', userId)

/**
 * Creates an order by constraint for the createdAt field.
 *
 * @param {'asc' | 'desc'} order - The order direction (ascending or descending).
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const orderCreatedAt = (order: 'asc' | 'desc') => orderBy('createdAt', order)

/**
 * Creates a where constraint for pending status.
 *
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereStatusActive = () => where('status', '==', UsefulFileStatus.ACTIVE)

/**
 * Creates a where constraint for pending status.
 *
 * @returns {QueryConstraint} A Firestore query constraint.
 */
export const whereStatusDeleted = () => where('status', '==', UsefulFileStatus.DELETED)
