import React from 'react'
import ExpandableTable from '../../components/Table/ExpandableTable'
import { timestampToDate } from '../../utils/dateTime.utils'
import { useTranslation } from 'react-i18next'
import TableSkeletonLoader from '../common/TableSkeleton'
import { TimeOffRequest } from '../../types/timeOff.types'
import { Typography } from '@mui/material'
import ActionButton from '../../components/Button/Button'
import {
  useApproverPendingTimeoffRequests,
  useApproveTimeoffRequest,
  useListenToApproverPendingTimeoffRequests,
  useRejectTimeoffRequest,
} from '../../hooks/useTimeOff' // Import the hooks and functions
import NoDataTable from '../common/NoDataTable'
import ErrorTable from '../common/ErrorTable'
import { hasPreviousPendingTimeOffApprovals } from '../../services/approval.service'
import { useAtom } from 'jotai'
import { userAtom } from '../../stores/userAtom'

const TimeOffApproverPendingList: React.FC = () => {
  const { t } = useTranslation()
  const [user] = useAtom(userAtom)

  // Start the real-time listener
  useListenToApproverPendingTimeoffRequests()

  // Get the list of pending time-off requests
  const { data: pendingRequests, isLoading, error } = useApproverPendingTimeoffRequests()

  const approve = useApproveTimeoffRequest()
  const reject = useRejectTimeoffRequest()

  if (isLoading) {
    return <TableSkeletonLoader rows={5} columns={6} />
  }

  if (error) {
    console.error(error)
    return (
      <ErrorTable
        title={t('timeOff.approverList.pending.error.title')}
        description={t('timeOff.approverList.pending.error.description')}
        message={t('timeOff.approverList.pending.error.message')}
      />
    )
  }

  if (!pendingRequests || pendingRequests.length === 0) {
    return (
      <NoDataTable
        title={t('timeOff.approverList.pending.noTimeOffRequests.title')}
        description={t('timeOff.approverList.pending.noTimeOffRequests.description')}
        message={t('timeOff.approverList.pending.noTimeOffRequests.message')}
      />
    )
  }

  return (
    <ExpandableTable
      headers={[
        t('timeOff.approverList.pending.header.name'),
        t('timeOff.approverList.pending.header.division'),
        t('timeOff.approverList.pending.header.role'),
        t('timeOff.approverList.pending.header.startDate'),
        t('timeOff.approverList.pending.header.endDate'),
        t('timeOff.approverList.pending.header.type'),
        t('timeOff.approverList.pending.header.amount'),
        t('timeOff.approverList.pending.header.createdAt'),
        '', // approve button
        '', // reject button
      ]}
      data={pendingRequests}
      getRowId={(timeOffRequest: TimeOffRequest) => timeOffRequest.id}
      renderRow={(timeOffRequest: TimeOffRequest) => [
        timeOffRequest.user?.name || '',
        timeOffRequest.user?.division || '',
        timeOffRequest.user?.role || '',
        timestampToDate(timeOffRequest.startDate),
        timestampToDate(timeOffRequest.endDate),
        timeOffRequest.type,
        timeOffRequest.amount,
        timestampToDate(timeOffRequest.createdAt),
        <ActionButton
          key={`approve-button-${timeOffRequest.id}`}
          buttonType="approve"
          onClick={() => approve(timeOffRequest.id)}
          disabled={hasPreviousPendingTimeOffApprovals(timeOffRequest, user?.authorityGroup)}
        >
          {t('timeOff.approverList.pending.approve')}
        </ActionButton>,
        <ActionButton
          key={`reject-button-${timeOffRequest.id}`}
          buttonType="reject"
          onClick={() => reject(timeOffRequest.id)}
          disabled={hasPreviousPendingTimeOffApprovals(timeOffRequest, user?.authorityGroup)}
        >
          {t('timeOff.approverList.pending.reject')}
        </ActionButton>,
      ]}
      renderExpandableContent={(timeOffRequest: TimeOffRequest) => <Typography sx={{ padding: '30px' }}>{timeOffRequest.reason}</Typography>}
    />
  )
}

export default TimeOffApproverPendingList
