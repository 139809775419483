import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query'
import { getDocs, onSnapshot, QueryConstraint } from 'firebase/firestore'
import {
  createFirestoreQuery,
  mapSnapshotToDocuments,
  writeDocument,
  updateDocument,
  enhanceDocumentsWithUserDetails,
} from '../services/firestore.service'
import { createSkipNextRealtimeAtom } from '../stores/firestoreAtoms'
import { useAtom } from 'jotai'
import { FirestoreData } from '../types/firestore.types'
import { removeDataUpdater, Updater } from '../utils/query.utils'
import { AuthorityGroup } from '../types/authorityGroup.types'
import { userAtom } from '../stores/userAtom'

/**
 * Custom hook to submit a document to a specified Firestore collection.
 *
 * @template TInput - The type of the input document data.
 * @template TOutput - The type of the output document data.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {(data: TInput) => TOutput | Promise<TOutput>} [transformData] - Optional function to transform the data before submission.
 * @returns {Mutation} A mutation object from react-query.
 *
 * @example
 * const writeDocument = useWriteDocument('collectionName');
 * writeDocument.mutate({ data: documentData });
 */
export const useWriteDocument = <TInput extends object, TOutput extends object>(
  collectionName: string,
  transformData?: (data: TInput) => TOutput | Promise<TOutput>
) => {
  return useMutation<string, Error, { data: TInput }>({
    mutationFn: async ({ data }) => {
      const dataToSubmit = transformData ? await transformData(data) : data
      return await writeDocument(dataToSubmit, collectionName)
    },
  })
}

/**
 * Custom hook to listen to a Firestore query in real-time and update the cache.
 *
 * @template T - The type of the document data.
 * @param {QueryKey} queryKey - The query key for react-query.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {Array<QueryConstraint>} [constraints] - Optional filters for the Firestore query.
 * @param {(oldData: T[], incomingDocuments: T[]) => T[]} [cacheUpdater] - Optional function to update the cache.
 *
 * @example
 * useListenToFirestoreQuery('[timeOff, pending]', 'timeOff', [{ field: 'status', operator: '==', value: 'pending' }]);
 */
export const useListenToFirestoreQuery = <T extends FirestoreData>(
  queryKey: QueryKey,
  collectionName: string,
  constraints?: QueryConstraint[],
  cacheUpdater?: (oldData: T[], incomingDocuments: T[], approverAuthority: AuthorityGroup) => T[]
) => {
  const [user] = useAtom(userAtom)

  if (!user || !user.authorityGroup) {
    throw new Error('User not found')
  }
  const approverAuthority: AuthorityGroup = user.authorityGroup
  const queryClient = useQueryClient()
  const skipNextRealTimeAtom = createSkipNextRealtimeAtom(collectionName)
  const [skipNextRealTime, setSkipNextRealTime] = useAtom(skipNextRealTimeAtom)
  const skipNextRealTimeRef = useRef(skipNextRealTime)

  useEffect(() => {
    skipNextRealTimeRef.current = skipNextRealTime
  }, [skipNextRealTime])

  useEffect(() => {
    const q = createFirestoreQuery(collectionName, constraints)
    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        if (skipNextRealTimeRef.current) {
          setSkipNextRealTime(false)
          return
        }

        const incomingDocuments = mapSnapshotToDocuments<T>(snapshot)
        const enhancedDocuments = await enhanceDocumentsWithUserDetails(incomingDocuments)

        queryClient.setQueryData(
          queryKey,
          cacheUpdater ? (oldData: T[] = []) => cacheUpdater(oldData, enhancedDocuments, approverAuthority) : enhancedDocuments
        )
      },
      (error) => console.error(`Error listening to documents:`, error)
    )
    return () => unsubscribe()
  }, [queryClient, setSkipNextRealTime, cacheUpdater])
}

/**
 * Custom hook to fetch data from a Firestore collection and update the cache.
 *
 * @template T - The type of the document data.
 * @param {QueryKey} queryKey - The query key for react-query.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {Array<QueryConstraint>} [constraints] - Optional filters for the Firestore query.
 * @returns {Query} A query object from react-query.
 *
 * @example
 * const { data, isLoading, error } = useFirestoreQuery('[timeOff, pending]', 'timeOff', [{ field: 'status', operator: '==', value: 'pending' }]);
 */
export const useFirestoreQuery = <T extends FirestoreData>(queryKey: QueryKey, collectionName: string, constraints?: QueryConstraint[]) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const q = createFirestoreQuery(collectionName, constraints)
      const snapshot = await getDocs(q)
      return mapSnapshotToDocuments<T>(snapshot)
    },
  })
}

/**
 * Custom hook to update a document in a Firestore collection and update the cache.
 *
 * @template T - The type of the document data.
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {(data: T) => object} firebaseUpdater - Function to update the document data.
 * @param {QueryKey} removeQueryKey - The query key for the old cache.
 * @param {QueryKey} addQueryKey - The query key for the new cache.
 * @param {Updater<T>} cacheAddUpdater - Function to update the cache.
 * @returns {Function} A function to update the document and cache.
 *
 * @example
 * const updateDocument = useUpdateDocument('timeOff', (data) => ({ status: 'approved' }), '[timeOff, pending]', '[timeOff, approved]', (oldData, document) => [...oldData, document]);
 * updateDocument(documentId);
 */
export const useUpdateDocument = <T extends FirestoreData>(
  collectionName: string,
  firebaseUpdater: (data: T) => object,
  removeQueryKey: QueryKey,
  addQueryKey: QueryKey,
  cacheAddUpdater: Updater<T>
) => {
  const skipNextRealTimeAtom = createSkipNextRealtimeAtom(collectionName)
  const [_skipNextRealTime, setSkipNextRealTime] = useAtom(skipNextRealTimeAtom)
  const queryClient = useQueryClient()

  const updateDocumentAndCache = async (documentId: string) => {
    // skip the next real-time update from firestore listener
    setSkipNextRealTime(true)
    const fullData = queryClient.getQueryData<T[]>(removeQueryKey)?.find((doc) => doc.id === documentId)
    if (!fullData) {
      return
    }
    // update the document in firestore
    const data = firebaseUpdater(fullData)
    await updateDocument(documentId, data, collectionName)
    // update the cache
    // remove the document from the old query cache
    queryClient.setQueryData(removeQueryKey, (oldData: T[]) => removeDataUpdater(oldData, fullData))
    // add the document to the new query cache
    queryClient.setQueryData(addQueryKey, (oldData: T[]) => cacheAddUpdater(oldData, fullData))
  }

  return updateDocumentAndCache
}
